<template>
  <div
    class="min-h-screen pb-14 bg-right bg-cover bg-gray-100"
    :style="{ backgroundImage: `url(${require('@/assets/img/bg.svg')})` }">
    <slot />
  </div>
</template>
<script>

export default {
  name: 'MinimalBgLayout',
}
</script>

